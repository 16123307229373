// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import { createApp } from 'vue';
import UIComponentLibrary from '@kaybird/ui-component-library';
import "@/@kaybird/ui-component-library/dist/ui-library.css";
import * as components from './components'
import "trix"
import "@rails/actiontext"


document.addEventListener('DOMContentLoaded', () => {
  const app = createApp({})
  for (const componentName in components) {
    const component = components[componentName]
    app.component(component.name, component)
  }
  app.use(UIComponentLibrary)
  app.mount('#app')
});

document.addEventListener("trix-before-initialize", () => { 
  Trix.config.blockAttributes.default.tagName = 'p';
  Trix.config.blockAttributes.heading1.tagName = 'h2'; 
});
